import { lazy } from 'react';
import { RouteConfig } from '../types/router';
import { ADD_PATIENT, BASE_ROUTE, DASHBOARD, NOT_FOUND, FORGOT, RESET, PATIENTS } from '@consts/paths';

const Login = lazy(() => import('../pages/Login'));
const Forgot = lazy(() => import('../pages/Auth/ForgotPassword'));
const Reset = lazy(() => import('../pages/Auth/ResetPassword'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Patients = lazy(() => import('../pages/Patients'));
const AddPatient = lazy(() => import('../pages/AddPatient/AddPatient'));
const NotFound = lazy(() => import('../pages/NotFound'));

const routes: RouteConfig[] = [
  {
    path: BASE_ROUTE,
    element: Login,
  },
  {
    path: FORGOT,
    element: Forgot,
  },
  {
    path: RESET,
    element: Reset,
  },
  {
    path: DASHBOARD,
    element: Dashboard,
  },
  {
    path: PATIENTS,
    element: Patients,
  },
  {
    path: ADD_PATIENT,
    element: AddPatient,
  },
  {
    path: NOT_FOUND,
    element: NotFound,
  },
];

export default routes;
