import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
import routes from './routes';
import { AuthGuard } from '@components/common/AuthGuard';

const AppRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route) => {
          if (route.authRequired || route.roles) {
            // Protected routes
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<AuthGuard authRequired={route.authRequired} roles={route.roles} />}
              >
                {/* Render the element or nested children */}
                {route.children ? (
                  route.children.map((child) => (
                    <Route key={child.path} path={child.path} element={<child.element />} />
                  ))
                ) : (
                  <Route index element={<route.element />} />
                )}
              </Route>
            );
          } else {
            // Public routes
            return (
              <Route key={route.path} path={route.path} element={<route.element />}>
                {route.children &&
                  route.children.map((child) => (
                    <Route key={child.path} path={child.path} element={<child.element />} />
                  ))}
              </Route>
            );
          }
        })}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
